import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const Financial = ({ data }) => (
  <Layout>
    <SEO title="Financial Information" />
    <div className="container">
      <div className="banner">
        <h1>Financial Resources</h1>
      </div>
      <div className="featured-content">
        <h2 className="headline">Financial Information / Insurance</h2>
        <p className="rich-text">
          Dr. Clauss has elected to participate as an “out-of-network” provider
          for all dental insurance companies. Our office is committed
          to helping you maximize your insurance benefits and we will assist in
          filing claims to your insurance provider. If you have questions about
          your dental insurance please call our office at (716) 662-3678. Dental
          insurance policies vary greatly, and we are happy to review your
          insurance and answer any questions you might have.
        </p>
        <p>
          We offer financing options to our patients through Care Credit. For
          more information on financing options please call our office or visit 
          <a href="https://carecredit.com"> CareCredit.com</a>.
        </p>
      </div>
      <div className="featured-image">
        <Img fluid={data.finance.childImageSharp.fluid} alt="financial-resources"/>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    finance: file(relativePath: { eq: "financial_hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700, quality: 100) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Financial
